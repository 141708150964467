import React from 'react';
import Background from '../../../primitives/backgrounds';
import Container from '../../../primitives/grid/container';
import Row from '../../../primitives/grid/row';
import Col from '../../../primitives/grid/col';
import Content from './content';
import type { HeroProps } from './index';
import TabletHorizontal from '../../../primitives/devices/tablet-horizontal';
import MediaItem from '../../../primitives/media-item';
import Mobile from '../../../primitives/devices/mobile';
import QualityBadges from '../../../primitives/quality-badges';
import { breakpoints } from '../../../primitives/tokens';

const TabletAndMobileHero = (props: HeroProps): JSX.Element => {
    const [mobileFinishedPlay, setMobileFinishedPlay] = React.useState(false);
    const { visual } = props;
    return (
        <>
            <Background
                css={{
                    zIndex: 1,
                    position: 'relative',
                    overflow: 'hidden',
                }}
                breakpoints={{
                    dt: {
                        clip: 'bottom',
                        gradient: 'greyAlphaRadial',
                    },
                }}
            >
                <Container>
                    <Row>
                        <Col breakpoints={{ dt: { span: 6 } }}>
                            <div css={{
                                padding: '176px 0',
                                [breakpoints.tb]: {
                                    padding: '126px 0',
                                },
                                [breakpoints.mb]: {
                                    padding: '70px 0',
                                },
                            }}
                            >
                                <Content bigTitleOnMobile {...props} />
                            </div>
                        </Col>
                    </Row>
                    <div css={{
                        position: 'absolute',
                        width: '50%',
                        right: '0',
                        top: '0',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        [breakpoints.mb]: {
                            width: '100%',
                            position: 'relative',
                            marginBottom: '-60px',
                            left: '5%',
                        },
                    }}
                    >
                        <div css={{
                            position: 'relative',
                            margin: 'auto 0',
                            width: '108%',
                            maxWidth: '650px',
                            top: '-8%',
                        }}
                        >
                            <TabletHorizontal css={{
                                right: '-23%',
                                [breakpoints.tb]: {
                                    right: '-30%',
                                },
                                [breakpoints.mb]: {
                                    right: '-16%',
                                },
                            }}
                            >
                                {(visual && visual[0] && visual[0].tabletMediaItem) && (
                                    <MediaItem
                                        css={{
                                            height: '100%',
                                            width: '100%',
                                        }}
                                        objectFit="cover"
                                        objectPosition="center center"
                                        item={visual[0].tabletMediaItem}
                                        autoPlay={mobileFinishedPlay}
                                        cannotOverrideInline
                                        noAnimate
                                        muted
                                        onPlayStateChange={(state) => {
                                            if (state === 'pause') {
                                                setMobileFinishedPlay(false);
                                            }
                                        }}
                                    />
                                )}
                                <div css={{
                                    position: 'absolute',
                                    left: '-26%',
                                    bottom: '-10%',
                                    width: '32%',
                                    zIndex: 1,
                                }}
                                >
                                    <Mobile>
                                        {(visual && visual[0] && visual[0].mobileMediaItem) && (
                                            <MediaItem
                                                css={{
                                                    height: '100%',
                                                    borderRadius: '1.5vh',
                                                    overflow: 'hidden',
                                                    transform: 'translateZ(0)',
                                                }}
                                                objectFit="cover"
                                                autoPlay={!mobileFinishedPlay}
                                                muted
                                                noAnimate
                                                objectPosition="center center"
                                                item={visual[0].mobileMediaItem}
                                                cannotOverrideInline
                                                onPlayStateChange={(state) => {
                                                    if (state === 'pause') {
                                                        setMobileFinishedPlay(true);
                                                    }
                                                }}
                                            />
                                        )}
                                    </Mobile>
                                </div>
                            </TabletHorizontal>
                            <div css={{
                                position: 'absolute',
                                bottom: '-9%',
                                right: '14%',
                                zIndex: 3,
                                [breakpoints.tb]: {
                                    right: '7%',
                                },
                                [breakpoints.mb]: {
                                    display: 'none',
                                },
                            }}
                            >
                                <QualityBadges />
                            </div>
                        </div>
                    </div>
                </Container>
            </Background>
            <div css={{
                display: 'none',
                [breakpoints.mb]: {
                    display: 'block',
                    position: 'absolute',
                    zIndex: '10',
                    width: '170px',
                    left: '50%',
                    transform: 'translateX(-50%)',
                },
            }}
            >
                <QualityBadges />
            </div>
        </>
    );
};
export default TabletAndMobileHero;
